import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import BootstrapVue3 from "bootstrap-vue-3";
import Hotjar from "@hotjar/browser";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue-3/dist/bootstrap-vue-3.css";
import "./index.css";
// import "./assets/scss/index.scss";

// import Datepicker from "vue3-date-time-picker";
// import "vue3-date-time-picker/dist/main.css";

import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

const siteId = process.env.VUE_APP_HOTJAR_KEY;
const hotjarVersion = 6;

Hotjar.init(siteId, hotjarVersion);

/* import the fontawesome core */
import { library } from "@fortawesome/fontawesome-svg-core";

/* import font awesome icon component */
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

/* import specific icons */
import {
  faEye,
  faEyeSlash,
  faChevronRight,
  faChevronLeft,
  faPenToSquare,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";

/* add icons to the library */
library.add([
  faEye,
  faEyeSlash,
  faChevronRight,
  faChevronLeft,
  faPenToSquare,
  faDownload,
]);

const app = createApp(App).use(BootstrapVue3).use(store).use(router).use(Toast);
app.component("FontAwesomeIcon", FontAwesomeIcon);

app.mount("#app");
